import React, {Component} from 'react';

export default class MentorManagement extends Component {
        constructor(props) {
        super(props)
    
        this.state = {
        }
    }
    
    render() {
        return <div>
        
        Manage Mentors
        
        </div>
    }
}